import { Controller } from "@hotwired/stimulus"
import { get } from "@rails/request.js"

export default class extends Controller {
    static targets = [ "billingName","billingAddress","shippingName","shippingAddress" ]

  change(event) {
    const client = event.target.selectedOptions[0].value;
    get(`/clients/${client}.json`)
      .then(response => response.json.then(
        data => (
          this.billingNameTarget.value = data.contact_person,
          this.billingAddressTarget.value = data.company_address,
          this.shippingNameTarget.value = data.contact_person,
          this.shippingAddressTarget.value = data.company_address
        )
      ));

  }
}
