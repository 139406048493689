// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
import 'bootstrap'
import '../controllers'
require("@rails/ujs").start()
require("@rails/activestorage").start()
require("@hotwired/turbo-rails")
require("@hotwired/stimulus")
require("channels")
require("controllers")

import '../css/application.css'

import jQuery from "jquery"
import $ from 'jquery'

// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

//= require filterrific/filterrific-jquery

document.addEventListener("turbo:load", () => {
  $('[data-toggle="tooltip"]').tooltip()
})

